import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// icons
import { FiArrowLeft } from "react-icons/fi";

const LeftBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 18%;
    height: 100vh;
    background-color: #f9fafc;
    padding: 20px 30px;
`;

const NavigationButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const ButtonSetting = styled.button`
    padding: 8px 20px;
    outline: none;
    border: none;
    background-color: #f3f4f8;
    color: #161c22;
    border-radius: 6px;
    font-size: 16px;
    font-family: 'Archivo', sans-serif;
    text-align: start;
    margin-top: 10px;
`;

export default function NavigationProfile() {
    return(
        <LeftBar>
            <NavigationButton>
                <a href='/home'>
                    <FiArrowLeft size={18} color="#505768" />
                </a>
                <span style={{ fontFamily: 'Archivo', fontWeight: 500, color: '#505768' }}>Configurações</span>
            </NavigationButton>
            <ButtonSetting>
                Perfil
            </ButtonSetting>
        </LeftBar>
    );
}