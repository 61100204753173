import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { encryptMessage } from '../../Utils/crypto/cryptoUtils';

import { 
    Avatar,
    CircularProgress,
    Button,
    Input,
    Image
} from '@chakra-ui/react'

import { db } from '../../Utils/database/firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';

import { SmallAddIcon } from '@chakra-ui/icons'
import { 
    MdFormatBold,
    MdFormatItalic,
    MdOutlineFormatQuote,
    MdOutlineLink,
    MdUpload 
} from "react-icons/md";

const BoxNewPost = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    background: #FFF;
    box-shadow: 0 1px 2px var(rgba(0, 0, 0, 0.2));
    border-radius: 10px;
    padding: 20px 35px;
    position: relative;
`;

const AlignUserInfo = styled.div`
    width: 100%;
    height: 20px;
`;

const AlignButtonsToEdit = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 10px 0 0 0;
    border-top: 1px solid #EEE;
`;

const ButtonEditFunction = styled.button`
    outline: none;
    border: none;
    background: #FFF;
    padding: 5px 10px;
    border-radius: 4px;
    transition: ease-in 0.1s;

    &:hover {
        background: #EEE;
        transition: ease-in 0.1s;
    }
`;

export default function NewPost({ onPost }) {
    const [postText, setPostText] = useState('');
    const [isBeta, setIsBeta] = useState(false);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [hasImage, setHasImage] = useState(false);
    const textareaRef = useRef(null);

    // Formatação para base64
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }   

    const applyFormat = (format) => {
        const textarea = textareaRef.current;
        /*if (textarea) {
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const selectedText = textarea.value.substring(start, end);
            let formattedText;

            switch (format) {
                case 'bold':
                    formattedText = `<b>${selectedText}</b>`;
                    break;
                case 'italic':
                    formattedText = `<i>${selectedText}</i>`;
                    break;
                case 'link':
                    const url = prompt('Enter the URL');
                    formattedText = `<a href="${url}" target="_blank">${selectedText}</a>`;
                    break;
                case 'quote':
                    formattedText = `<blockquote>${selectedText}</blockquote>`;
                    break;
                default:
                    formattedText = selectedText;
            }

            setPostText(prev => prev.slice(0, start) + formattedText + prev.slice(end));
        }*/
    };

    const handlePost = async () => {
        if (postText.trim() !== '' && localStorage.getItem('email')) {
            const fullName = localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
            const userId = localStorage.getItem('uid');
            const descProfile = localStorage.getItem('descProfile');
    
            try {
                await addDoc(collection(db, 'posts'), {
                    text: encryptMessage(postText),
                    author: fullName,
                    userId: userId,
                    description: descProfile,
                    timestamp: Timestamp.fromDate(new Date()),
                    isBeta,
                    photo: localStorage.getItem('photo'),
                    uploadedFile: image
                });
                setPostText('');
                setHasImage(false);
                setImage(null);
            } catch (e) {
                console.error("Erro ao adicionar o post: ", e);
            }
        }
    };

    // Buscar tags personalizadas
    useEffect(() => {
        const searchUser = async () => {
            try{
                const response = await fetch('https://indecisive-grape-vanilla.glitch.me/api/v1/admin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ uid: localStorage.getItem('uid') }),
                });

                const data = await response.json();
                
                if(data.isBeta){
                    setIsBeta(data.isBeta);
                }
            } catch (error) {
                console.error('Algo deu errado:', error);
            }
        }

        searchUser();
    }, [])

    return (
        <BoxNewPost>
            <AlignUserInfo>
                <Avatar 
                    name={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} style={{ userSelect: 'none' }} 
                    src={localStorage.getItem('photo') || undefined}
                />
                <textarea 
                    ref={textareaRef} 
                    placeholder={`No que você está pensando hoje ${localStorage.getItem('email') ? ', ' + localStorage.getItem('firstName') : ''}?`}
                    style={{ width: 780, marginLeft: 15, backgroundColor: '#FFF', color: '#1c1e21', border: 'none', boxShadow: 'none', outline: 'none', fontFamily: 'Archivo', padding: '10px 0 0 10px', resize: 'none' }}
                    value={postText}
                    disabled={!localStorage.getItem('email')}
                    onChange={(e) => setPostText(e.target.value)}
                />
            </AlignUserInfo>
            <AlignButtonsToEdit>
                <Button colorScheme='blue' style={{ marginLeft: 15, fontSize: 12, height: 35, fontFamily: 'Archivo', borderRadius: 4, backgroundColor: '#3385ff' }} onClick={handlePost}>
                    <SmallAddIcon />
                    Publicar
                </Button>
                {/* Negrito */}
                <ButtonEditFunction onClick={() => applyFormat('bold')}>
                    <MdFormatBold />
                </ButtonEditFunction>

                {/* Itálico */}
                <ButtonEditFunction onClick={() => applyFormat('italic')}>
                    <MdFormatItalic />
                </ButtonEditFunction>

                {/* Aspas */}
                <ButtonEditFunction onClick={() => applyFormat('quote')}>
                    <MdOutlineFormatQuote />
                </ButtonEditFunction>

                {/* Transformar o texto em a href para abrir links */}
                <ButtonEditFunction onClick={() => applyFormat('link')}>
                    <MdOutlineLink />
                </ButtonEditFunction>

                {/* Upload de arquivo */}
                <ButtonEditFunction>
                    <label htmlFor='uploadPhoto'>
                        <MdUpload />
                    </label>
                    <Input 
                        type="file" 
                        id="uploadPhoto" 
                        accept="image/*"
                        hidden 
                        onChange={async (event) => {
                            setLoading(true);

                            const base64Image = await getBase64(event.target.files[0]);

                            try{
                                setHasImage(true);
                                setImage(base64Image)
                            } catch (error) {
                                console.error(error);
                            } finally {
                                setLoading(false);
                            }
                        }}
                    />
                </ButtonEditFunction>

                {
                    hasImage &&
                    <span
                        style={{
                            position: 'absolute', 
                            right: '65px',
                            fontFamily: 'Archivo',
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#666'
                        }}
                    >
                        Você está carregando um arquivo...
                    </span>
                }

                {
                    hasImage &&
                    <Image 
                        src={image} 
                        alt='Imagem postada para o site da Zameed' 
                        width='32px' 
                        height='32px' 
                        borderRadius={4} 
                        position='absolute' 
                        right='15px' 
                        cursor='pointer'
                        onClick={() => {
                            setImage(null);
                            setHasImage(false);
                        }}
                    />
                }

                {
                    loading &&
                    <CircularProgress isIndeterminate size='14px' />
                }
            </AlignButtonsToEdit>
        </BoxNewPost>
    );
}