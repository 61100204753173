import Masonry from "react-responsive-masonry";
import UserImagePost from "./UserImagePost";

/**
 * @component
 *
 * @typedef {{ src: string | URL, text: string }} ImageInfo
 * @typedef {{ images: ImageInfo[] }} ImageGridProps
 * @param {ImageGridProps} props
 */
export default function ImageGrid(props) {
    return (
        <Masonry columnsCount={3} gutter="3px">
            {props.images.map((i, idx) => (
                // TODO: alt text
                <UserImagePost src={i.src} text={i.text} key={idx} />
            ))}
        </Masonry>
    );
}
