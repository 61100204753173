import styled from "styled-components";

export const UserComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
`;

export const UserComponentText = styled.span`
    font-size: 16px;
    font-weight: 500;
    font-family: 'Archivo', sans-serif;
`;

export const DescriptionComponentText = styled.span`
    font-size: 13.5px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    color: #A3A3A3;
`;