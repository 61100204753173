import styled from "styled-components";
import UserComponent from "../UserComponent";
import Recommendations from "../Recommendations";

const MainLayout = styled.div({
    marginTop: "4rem",
    display: "flex",
    flexDirection: "row"
})

const LeftSide = styled.div({
    padding: "25px 65px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "75%",
    height: "auto",
    overflowY: "auto",
    gap: "20px"
});

const RightSide = styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "25%",
    height: "fit-content",
    overflowY: "auto",
    padding: "25px 0",
    position: "sticky",
    top: "0",
    gap: "20px"
});

/**
 * Componente de reuso do layout para feeds, no qual o conteudo dele é disposto
 * à esquerda, com o usercard e as recomendações do lado direto
 * @component
 *
 * @typedef {{ children: React.ReactNode }} CommonFeedLayoutProps
 * @param {CommonFeedLayoutProps} props
 */
// FIXME: layout para mobile
export default function CommonFeedLayout(props) {
    return (
        <MainLayout>
            <LeftSide>
                {props.children}
            </LeftSide>
            <RightSide>
                <UserComponent />
                <Recommendations />
            </RightSide>
        </MainLayout>
    )
}
