import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { 
    UserComponent,
    UserComponentText,
    DescriptionComponentText
} from "./styles/style";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Avatar,
    Image
} from '@chakra-ui/react'

export default function CommentModal({ isOpen, onClose, userName, description, textPost, timestamp, userId, userPhoto, uplodedFile }) {
    const [comment, setComment] = useState('');
    const textareaRef = useRef(null);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader style={{ display: 'flex', flexDirection: 'row', gap: 15, borderBottom: '1px solid #EEE' }}>
                    <Link to={`/profile/${userId}`}>
                        <Avatar 
                            name={userName} 
                            src={userPhoto || undefined}
                        />
                    </Link>
                    <UserComponent>
                        <Link to={`/profile/${userId}`}>
                            <UserComponentText>{userName}</UserComponentText>
                        </Link>
                        {
                            description !== null && description !== undefined && description !== '' &&
                            <DescriptionComponentText style={{ userSelect: 'none' }}>{description}</DescriptionComponentText>
                        }
                    </UserComponent>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody style={{ padding: '25px 25px', fontFamily: 'Roboto' }}>
                    {textPost}
                    {
                        uplodedFile &&
                        <Image 
                            src={uplodedFile || undefined} 
                            backgroundSize='contain' 
                            marginTop={18} 
                            style={{
                                maxWidth: '100%',
                                maxHeight: '400px',
                                objectFit: 'contain'
                            }}
                        />
                    }
                </ModalBody>
                <ModalFooter>
                    <span style={{ fontSize: 12, fontWeight: 400, color: '#666' }}>{timestamp}</span>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}