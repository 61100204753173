import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../Utils/database/firebase";

// icons
import { FiEdit2, FiDroplet } from "react-icons/fi";

// ChakraUI
import { 
    Divider,
    Avatar,
    AvatarBadge,
    AvatarGroup,
    Tag,
    TagLabel,
    TagLeftIcon,
} from '@chakra-ui/react'

// Icons
import { MdFileOpen, MdOutlineMessage } from "react-icons/md";

const UserDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    background: #FFF;
    margin-top: 65px;
    border-radius: 40px;
    position: relative;
    border: 1px solid #CCC;
    padding: 75px 0 10px 0;
    
    @media (max-width: 1070px) {
        display: none;
    }
`;

const UserPhotoComponent = styled.div`
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -90px;
`;

const UserPhoto = styled.div`
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    background: #FFF;
    position: relative;
    transform: rotate(0deg);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 75px;
        height: 75px;
        border-top-left-radius: 999px;
        border-top: 1px solid #CCC;
        border-left: 1px solid #CCC;
        background: transparent;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 75px;
        height: 75px;
        border-top-right-radius: 999px;
        border-top: 1px solid #CCC;
        border-right: 1px solid #CCC;
        background: transparent;
    }
`;

const UserName = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Archivo', sans-serif;
`;

const AboutUser = styled.span`
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    padding: 0 25px;
`;

const DefaultTextSemiBold = styled.span`
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Archivo', sans-serif;
`;

const DefaultText = styled.span`
    color: #6d6d6d;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif; 
`;

const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    padding: 10px 15px;
`;

const ActionsUser = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 0 40px;
`;

const ActionButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    outline: none;
    position: relative;
`;

export default function UserComponent() {
    const [isBeta, setIsBeta] = useState(false);
    const [followersCount, setFollowersCount] = useState(0);

    useEffect(() => {
        const searchUser = async () => {
            try{
                const response = await fetch('https://indecisive-grape-vanilla.glitch.me/api/v1/admin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ uid: localStorage.getItem('uid') }),
                });

                const data = await response.json();
                
                setIsBeta(data.isBeta);
            } catch (error) {
                console.error('Algo deu errado:', error);
            }
        }

        const fetchFollowersCount = async () => {
            const userId = localStorage.getItem('uid');
            if (userId) {
                const followersCollection = collection(db, "users", userId, "followers");
                const followersSnapshot = await getDocs(followersCollection);
                
                followersSnapshot.forEach((doc) => {
                    //console.log("Seguidor encontrado:", doc.id, doc.data());
                });
        
                setFollowersCount(followersSnapshot.size);
            }
        };

        searchUser();
        fetchFollowersCount();
    }, [])

    return (
        <UserDiv>
            <UserPhotoComponent>
                <UserPhoto>
                    <Link to={`/profile/${localStorage.getItem('uid')}`}>
                        <Avatar 
                            name={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} style={{ width: 110, height: 110 }} 
                            src={localStorage.getItem('photo')}
                        />
                    </Link>
                </UserPhoto>
            </UserPhotoComponent>
            <UserName>
                {localStorage.getItem('firstName')}
                {
                    isBeta &&
                    <Tag size='sm' colorScheme='cyan' style={{ margin: '0 5px'}}>
                        <TagLeftIcon as={FiDroplet} />
                        <TagLabel>BETA</TagLabel>
                    </Tag>
                }
                <a href='/profile'>
                    <FiEdit2 size={12} style={{ marginLeft: 5, cursor: 'pointer' }} />
                </a>
            </UserName>
            <AboutUser>        
            {
                localStorage.getItem('descProfile') === null || localStorage.getItem('descProfile') === 'undefined' || localStorage.getItem('descProfile') === undefined ?
                'Não há descrição'
                :
                localStorage.getItem('descProfile')
            }
            </AboutUser>

            <Divider style={{ marginTop: 20 }}/>

            <DefaultTextSemiBold style={{ marginTop: 15 }}>
                Suas informações
            </DefaultTextSemiBold>

            {/* Informações Pessoais */}
            <InfoDiv>
                <DefaultText>
                    Clientes atendidos: <span style={{ color: '#1265ef', fontStyle: 'normal', fontWeight: 600 }}>0</span>
                </DefaultText>
                <DefaultText>
                    Trabalhos pendentes: <span style={{ color: '#1265ef', fontStyle: 'normal', fontWeight: 600 }}>0</span>
                </DefaultText>
                <DefaultText>
                    Seguidores: <span style={{ color: '#1265ef', fontStyle: 'normal', fontWeight: 600 }}>{followersCount}</span>
                </DefaultText>
                <DefaultText>
                    Faturamento: <span style={{ color: '#1265ef', fontStyle: 'normal', fontWeight: 600 }}>R$0,00</span>
                </DefaultText>
            </InfoDiv>

            <Divider style={{ marginTop: 20 }}/>

            {/* Ações do Usuario */}
            <ActionsUser>
                <ActionButton>
                    <MdFileOpen size={24} color='#656565' />
                    <span style={{ fontSize: 12, fontWeight: 600, color: '#656565', marginTop: 5 }}>Contratos</span>
                </ActionButton>
                <ActionButton>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 20, top: 10, borderRadius: 999, width: 15, height: 15, textAlign: 'center', background: '#FF303A', color: '#FFF', fontSize: 10 }}>0</div>
                    <MdOutlineMessage size={24} color='#656565' />
                    <span style={{ fontSize: 12, fontWeight: 600, color: '#656565', marginTop: 5 }}>Mensagens</span>
                </ActionButton>
            </ActionsUser>
        </UserDiv>
    );
}