import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { 
    RecommendationsComponent,
    RecommendationsHeader,
    RecommendationsList,
    RecommendationsCard
} from './styles/style';

// ChakraUI
import { 
    Avatar
} from '@chakra-ui/react'

import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../Utils/database/firebase";

export default function Recommendations() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Realizar busca de todos os usuários
        const fetchAllUsers = async () => {
            try {
                const usersCollection = collection(db, "users");
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = await Promise.all(
                    usersSnapshot.docs.map(async (doc) => {
                        const userData = { id: doc.id, ...doc.data() };
                        const galleryRef = collection(doc.ref, "gallery");
                        const gallerySnapshot = await getDocs(galleryRef);
        
                        if (!gallerySnapshot.empty) {
                            userData.galleryImages = gallerySnapshot.docs.map(doc => doc.data().photo);
                        } else {
                            userData.galleryImages = [];
                        }
        
                        return userData;
                    })
                );
                setUsers(usersList);
            } catch (error) {
                console.error("Erro ao buscar usuários:", error);
            }
        };
    
        fetchAllUsers();
    }, []);

    return (
        <RecommendationsComponent>
            <RecommendationsHeader>Talvez você goste</RecommendationsHeader>
            <RecommendationsList>
                {users.slice(0, 3).map((user, index) => (
                    <RecommendationsCard>
                        <div key={index} style={{ width: '100%' }}>
                            <Link
                                to={`/profile/${user.id}`}
                            >
                                <div 
                                    style={{
                                        width: '100%',
                                        height: 130,
                                        backgroundImage: user.galleryImages && user.galleryImages.length > 0 
                                            ? `url(${user.galleryImages[0]})` 
                                            : 'none',
                                        backgroundSize: 'cover',
                                        objectFit: 'cover',
                                        position: 'relative',
                                    }}
                                >
                                    <div style={{ position: 'absolute', gap: 10, bottom: 0, width: '100%', height: 50, padding: '0 10px', display: 'flex', flexDirection: 'row', alignItems: 'center', background: 'linear-gradient(to top, rgba(31, 31, 31, 0.6) 0%, rgba(31, 31, 31, 0.02) 90%)', }}>
                                        <Avatar 
                                            name={user.firstName + " " + user.lastName}
                                            src={user.photo || undefined}
                                            size='sm'
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '220px' }}>
                                            <span style={{ fontFamily: 'Archivo', fontSize: 14, fontWeight: 600, color: '#FFF', textAlign: 'start' }}>{user.firstName + " " + user.lastName}</span>
                                            <span style={{ fontFamily: 'Archivo', fontSize: 12, fontWeight: 400, color: '#CCC', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.description}</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </RecommendationsCard>
                ))}
            </RecommendationsList>
        </RecommendationsComponent>
    )
}