import { useNavigate, useParams } from "react-router-dom";
import ImageGrid from "../../components/ImageGrid";
import { useEffect } from "react";
import CommonFeedLayout from "../../components/CommonFeedLayout";

const LOREM_IPSUM = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in arcu sapien. Sed molestie ex et orci placerat cursus. Praesent maximus tempor justo ac rutrum. In dignissim malesuada dignissim.'


// TODO: buscar imagens do backend, com alguma lógica muito épica
const SAMPLE_IMAGES = [
    'https://img.freepik.com/free-psd/3d-house-building-property_23-2151682358.jpg',
    'https://img.freepik.com/free-photo/modern-residential-district-with-green-roof-balcony-generated-by-ai_188544-10276.jpg',
    'https://img.freepik.com/free-photo/neo-brutalism-inspired-building_23-2151004724.jpg',
    'https://img.freepik.com/free-photo/ai-generated-house-design_23-2150666239.jpg',
    'https://img.freepik.com/free-photo/monochromatic-urban-minimal-landscape_23-2151011551.jpg',
    'https://img.freepik.com/free-photo/monochromatic-urban-minimal-landscape_23-2151011484.jpg',
]

export default function TopicFeed() {
    const navigate = useNavigate()
    /** @type { { feedName: string } } */
    const params = useParams()
    const feedName = params.feedName;

    useEffect(() => {
        /**
         * parametros de rota possiveis, provenientes do
         * [Header](../../components/Header/index.js)
         */
        const possibleParams = [
            'arquitetura',
            'design_de_interiores',
            'paisagismo',
            'construtoras',
            'modelagem_3d',
            'moveis_planejados',
            'luminotecnia',
        ]

        if (feedName === undefined) {
            console.error(`missing route parameter feedName: ${feedName}`)
            navigate('/home')
        }
        if (!possibleParams.includes(feedName)) {
            console.error(`invalid feed name: ${feedName}`)
            navigate('/home')
        }
    })

    // encher linguiça
    let posts = []
    for (let i = 0; i <= 10; i++) {
        const idx = Math.floor(Math.random() * SAMPLE_IMAGES.length);
        posts.push({
            src: SAMPLE_IMAGES[idx],
            text: LOREM_IPSUM
        })
    }

    return (
        <CommonFeedLayout>
            <ImageGrid images={posts} />
        </CommonFeedLayout>
    )
}
