import styled from "styled-components"

const BORDER_RADIUS = "1rem"

const Container = styled.div({
    position: "relative",
    width: "100%",
    borderRadius: BORDER_RADIUS,
})

const Image = styled.img({
    borderRadius: BORDER_RADIUS,
    width: "100%"
})

const PostText = styled.div({
    color: "white",
})

const BottomShade = styled.div({
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    padding: "1rem 1rem 0.5rem 1rem",
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    bottom: "0",
    width: "100%",
    height: "fit-content",
    background: "linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,0) 100%)"
});

/**
 * Post de Usuário somente com imagem, com o texto descritivo do post sobreposto
 * no canto inferior esquerdo
 * @component
 *
 * @typedef {{
 *     src: string | URL,
 *     text: string,
 *     alt?: string
 * }} UserImagePostProps
 * @param {UserImagePostProps} props
 */
export default function UserImagePost(props) {
    return(
        <Container>
            <Image src={props.src} alt={props.alt} />
            <BottomShade>
                <PostText>{props.text}</PostText>
            </BottomShade>
        </Container>
    )
}
