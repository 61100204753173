import styled from "styled-components";

export const RecommendationsComponent = styled.div`
    width: 80%;
    height: 90vh;
    background-color: #FFF;
    border-radius: 40px;
    text-align: center;
    padding: 15px 0;
`;

export const RecommendationsHeader = styled.span`
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Archivo', sans-serif;
`;

export const RecommendationsList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 0;
    gap: 10px;
`;

export const RecommendationsCard = styled.div`
    width: 100%;
    height: 20vh;
`;