import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../Utils/database/firebase';

import UserPost from '../UserPost';
import NewPost from '../NewPost';
import CommonFeedLayout from '../CommonFeedLayout';

const ComponentToCreateNewPost = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export default function HomeComponent() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'posts'), (snapshot) => {
            const postsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            const sortedPosts = postsData.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());

            setPosts(sortedPosts);
        });

        return () => unsubscribe();
    }, []);

    return (
        <CommonFeedLayout>
                {/* Criar nova publicação */}
                <ComponentToCreateNewPost>
                    <NewPost />
                </ComponentToCreateNewPost>

                {/* POSTS */}
                {posts.map(post => (
                    <UserPost key={post.id} text={post.text} description={post.description} author={post.author} timestamp={post.timestamp} userId={post.userId} isBeta={post.isBeta} userPhoto={post.photo} uplodedFile={post.uploadedFile} />
                ))}
        </CommonFeedLayout>
    );
}
