import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import Slider from 'react-slick'
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { doc, getDoc, collection, query, where, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../../../Utils/database/firebase";

// Componentes externos
import UserPost from "../../../components/UserPost";

import { 
    FiDroplet,
    FiCheck,
    FiUserPlus,
    FiAlertCircle,
    FiPhone,
    FiShare2
} from "react-icons/fi";

import { 
    BiChevronLeft,
    BiChevronRight,
    BiCarousel
} from 'react-icons/bi'

import { 
    Avatar,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Tabs, 
    TabList, 
    TabPanels,
    TabPanel,
    Tab, 
    Tag,
    TagLabel,
    TagLeftIcon,
    IconButton,
    Box,
    useBreakpointValue,
    Input,
    Image,
    CircularProgress
} from '@chakra-ui/react'

const VisualizationComponent = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 60px;
    max-height: 100%;
    overflow-y: auto;
    background: #FFF;
`;

const UserComponent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 65vh;
    position: relative;
`;

const BannerUserDefault = styled.div`
    width: 100%;
    height: 50vh;
    background: rgb(124,63,0);
    background: linear-gradient(90deg, rgba(124,63,0,1) 0%, rgba(217,243,255,1) 0%, rgba(173,234,253,1) 50%, rgba(50,210,255,1) 100%);
`;

const UserInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75%;
`;

const UserAvatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    background: #FFF;
    border-radius: 999px;
    position: absolute;
    top: 190px;
    left: 25px;
    padding: 5px;
`;

const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 25px;
    margin-top: 78px;
`;

const UserNameAndDescription = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    height: auto;
    gap: 10px;
`;

const UsernameText = styled.span`
    font-size: 20px;
    font-weight: 600;
    font-family: 'Archivo', sans-serif;
`;

const UsernameDescription = styled.span`
    font-size: 14px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    color: #A3A3A3;
    margin-top: 2px;
`;

const UserTag = styled.span`
    font-size: 14px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    color: #8b9d99;
`;

const UserFollowers = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 250px;
    height: auto;
`;

const FollowersText = styled.span`
    font-size: 14px;
    font-weight: 600;
    font-family: 'Archivo', sans-serif;
    color: #000;
`;

const DefaultText = styled.span`
    font-size: 14px;
    font-weight: 400;
    font-family: 'Archivo', sans-serif;
    color: #8b9d99;
    margin-left: 3px;
`;

const ProfileButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid #4a99dc;
    color: #4a99dc;
    padding: 5px 20px;
    border-radius: 100px;
    font-family: 'Archivo', sans-serif;
    font-weight: 600;
    background: none;
    transition: ease-in 0.1s;

    &:hover {
        background: #4a99dc;
        color: #FFF;
        transition: ease-in 0.1s;
    }
`;

const ProfileButtonLabel = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid #4a99dc;
    color: #4a99dc;
    padding: 5px 20px;
    border-radius: 100px;
    font-family: 'Archivo', sans-serif;
    font-weight: 600;
    background: none;
    transition: ease-in 0.1s;

    &:hover {
        background: #4a99dc;
        color: #FFF;
        transition: ease-in 0.1s;
        cursor: pointer;
    }
`;

// Configurações para o Slider de imagens
const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500
}

export default function Visualization() {
    const { id } = useParams();
    const [userData, setUserData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const [followersCount, setFollowersCount] = useState(0);
    const [slider, setSlider] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [userPosts, setUserPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const top = useBreakpointValue({ base: '90%', md: '50%' });
    const side = useBreakpointValue({ base: '30%', md: '10px' });

    // Realizar busca das informações do usuário
    useEffect(() => {
        // Buscar informações pessoais
        const fetchUserData = async () => {
          if (id) {
            const userDoc = doc(db, "users", id);
            const userSnap = await getDoc(userDoc);
            if (userSnap.exists()) {
              setUserData(userSnap.data());
            } else {
              console.log("Usuário não encontrado com esse UID.");
            }
          }
        };

        // Checar se está seguindo o usuário
        const checkIfFollowing = async () => {
            const uid = localStorage.getItem('uid');
            if (uid && id) {
                const followingCollection = collection(db, "users", uid, "following");
                const q = query(followingCollection, where("followingId", "==", id));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    setIsFollowing(true);
                }
            }
        };

        // Checar a quantidade de seguidores
        const fetchFollowersCount = async () => {
            if (id) {
                const followersCollection = collection(db, "users", id, "followers");
                const followersSnapshot = await getDocs(followersCollection);
                
                followersSnapshot.forEach((doc) => {
                    //console.log("Seguidor encontrado:", doc.id, doc.data());
                });

                setFollowersCount(followersSnapshot.size);
            }
        };

        // Buscar imagens da Galeria do usuário
        const fetchUserGallery = async () => {
            if (id) {
                const galleryRef = collection(doc(db, "users", id), "gallery");
                const gallerySnapshot = await getDocs(galleryRef);

                if (!gallerySnapshot.empty) {
                    const images = gallerySnapshot.docs.map(doc => doc.data().photo);
                    setGalleryImages(images);
                } else {
                    console.log("Nenhuma imagem encontrada na galeria.");
                }
            }
        };
        
        // Buscar postagens do usuário
        const fetchUserPosts = async () => {
            if (id) {
                const posts = onSnapshot(collection(db, "posts"), (snapshot) => {
                    const postsData = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    const filteredPostsToUserId = postsData.filter(item => item.userId === id);

                    setUserPosts(filteredPostsToUserId);
                })
            }
        }

        fetchUserData();
        checkIfFollowing();
        fetchFollowersCount();
        fetchUserGallery();
        fetchUserPosts();
    }, [id]);

    const handleFollowUser = async () => {
        try {
            const uid = localStorage.getItem('uid');
            const url = isFollowing 
                ? 'https://indecisive-grape-vanilla.glitch.me/api/v1/unfollow/user'
                : 'https://indecisive-grape-vanilla.glitch.me/api/v1/follow/user';
            
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: uid, followingId: id }),
            });

            if (response.ok) {
                setIsFollowing(!isFollowing);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }  

    async function uploadGallery(event) {
        setIsLoading(true);

        const file = event.target.files[0];

        if (!file) {
            setIsLoading(false);
            toast.info('Nenhuma foto foi selecionada.');
            return;
        }

        setTimeout(async () => {
            try{
                const base64Photo = await getBase64(file);

                const response = await fetch('https://indecisive-grape-vanilla.glitch.me/api/v1/users/edit/gallery', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: localStorage.getItem('uid'),
                        photo: base64Photo,
                    }),
                });

                const result = await response.json();

                if(response.ok) {
                    toast.success('Sua galeria foi atualizada com sucesso!');
                } else {
                    toast.error('Erro ao atualizar foto de perfil.');
                }
            } catch (error) {
                toast.error(`Erro ao atualizar foto de perfil: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        }, 5000)
    }

    if (!userData) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100%', padding: '75px 45px', backgroundColor: '#FFF', gap: 10 }}>
                <FiAlertCircle size={128} />
                <span style={{ fontFamily: 'Archivo', fontSize: 24, fontWeight: '700' }}>Usuário não encontrado</span>
                <span style={{ fontFamily: 'Archivo', fontSize: 18 }}>Esse usuário foi excluido ou é inexistente, tente novamente mais tarde.</span>
            </div>
        );
    }

    return (
        <>
            <VisualizationComponent>
                <UserComponent>
                    {/*<BannerUserDefault />*/}
                    <Box position={'relative'} height={'100%'} width={'full'} overflow={'hidden'}>
                        {/* Left Icon */}
                        <IconButton
                            aria-label="left-arrow"
                            backgroundColor="#f0f4f7"
                            borderRadius="full"
                            position="absolute"
                            left={side}
                            top={top}
                            transform={'translate(0%, -50%)'}
                            marginLeft={7}
                            zIndex={2}
                            onClick={() => slider?.slickPrev()}>
                            <BiChevronLeft />
                        </IconButton>
                        {/* Right Icon */}
                        <IconButton
                            aria-label="right-arrow"
                            backgroundColor="#f0f4f7"
                            borderRadius="full"
                            position="absolute"
                            right={side}
                            top={top}
                            transform={'translate(0%, -50%)'}
                            marginRight={7}
                            zIndex={2}
                            onClick={() => slider?.slickNext()}>
                            <BiChevronRight />
                        </IconButton>
                        <Slider {...settings} ref={(slider) => setSlider(slider)}>
                            {galleryImages.map((image, index) => (
                                <Box
                                    key={index}
                                    height={'100vh'}
                                    position="relative"
                                    borderLeft={'4px solid #FFF'}
                                    borderRight={'4px solid #FFF'}
                                >
                                    <Image src={image} alt={`Imagens da galeria do ${userData.firstName}`} objectFit="cover" />
                                </Box>
                            ))}
                        </Slider>
                    </Box>
                    <UserInformation>
                        <UserAvatar>
                            <Avatar 
                                name={userData.firstName + " " + userData.lastName}
                                src={userData.photo || undefined}
                                width='150px' height='150' 
                            />
                        </UserAvatar>
                        <UserDetails>
                            {
                                userData.isBeta &&
                                <Tag size='sm' colorScheme='cyan' style={{ width: 70 }}>
                                    <TagLeftIcon as={FiDroplet} />
                                    <TagLabel>BETA</TagLabel>
                                </Tag>
                            }
                            <UserNameAndDescription>
                                <UsernameText>{userData.firstName + " " + userData.lastName}</UsernameText>
                                <UsernameDescription>{userData.description}</UsernameDescription>
                            </UserNameAndDescription>
                            <UserTag>
                                {
                                    userData &&
                                    `@${userData.firstName}`
                                }
                            </UserTag>
                            <UserFollowers>
                                {
                                    userData &&
                                    <FollowersText>
                                        {followersCount}
                                        <DefaultText>
                                            Seguidores
                                        </DefaultText>
                                    </FollowersText>
                                }
                                {
                                    userData &&
                                    <FollowersText>
                                        0
                                        <DefaultText>
                                            Clientes atendidos
                                        </DefaultText>
                                    </FollowersText>
                                }
                            </UserFollowers>
                        </UserDetails>
                        <div style={{ position: 'absolute', marginTop: 25, right: 25, display: 'flex', flexDirection: 'row', gap: 10 }}>
                            {
                                localStorage.getItem('email') !== userData.email &&
                                <ProfileButton style={{ right: 135, border: 'none' }}>
                                    <FiShare2 style={{ marginRight: 5 }} />
                                    Compartilhar
                                </ProfileButton>
                            }
                            {
                                localStorage.getItem('email') !== userData.email &&
                                <Popover>
                                    <PopoverTrigger>
                                        <ProfileButton style={{ right: 135, border: 'none' }}>
                                            <FiPhone style={{ marginRight: 5 }} />
                                            Contato
                                        </ProfileButton>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <PopoverHeader>Contato</PopoverHeader>
                                        <PopoverBody style={{ fontWeight: '500', fontFamily: 'Archivo' }}>{userData.phone}</PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            }
                            {
                                localStorage.getItem('uid') === id &&
                                <>
                                    <ProfileButtonLabel htmlFor='inputGallery'>
                                        {
                                            isLoading ?
                                            <CircularProgress isIndeterminate size={4} />
                                            :
                                            <BiCarousel />
                                        }
                                    </ProfileButtonLabel>
                                    <Input 
                                        type="file" 
                                        id="inputGallery" 
                                        accept="image/*"
                                        hidden 
                                        onChange={uploadGallery}
                                    />
                                </>
                            }
                            {
                                localStorage.getItem('uid') === id ?
                                <Popover>
                                    <PopoverTrigger>
                                        <ProfileButton>
                                            Visualizar meu ID
                                        </ProfileButton>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <PopoverHeader>Aqui está seu ID</PopoverHeader>
                                        <PopoverBody style={{ fontWeight: '800', fontFamily: 'Archivo' }}>{localStorage.getItem('uid')}</PopoverBody>
                                    </PopoverContent>
                                </Popover>
                                :
                                <ProfileButton onClick={handleFollowUser}>
                                    {isFollowing ? <FiCheck style={{ marginRight: 5 }} /> : <FiUserPlus style={{ marginRight: 5 }} />}
                                    {isFollowing ? "Seguindo" : "Seguir"}
                                </ProfileButton>
                            }
                        </div>
                    </UserInformation>
                </UserComponent>
                <Tabs>
                    <TabList>
                        <Tab style={{ fontFamily: 'Archivo' }}>Postagens</Tab>
                        <Tab style={{ fontFamily: 'Archivo' }}>Projetos</Tab>
                        <Tab style={{ fontFamily: 'Archivo' }}>Avaliações</Tab>
                        <Tab style={{ fontFamily: 'Archivo' }}>Marcados</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            {userPosts.map(post => (
                                <UserPost key={post.id} text={post.text} description={post.description} author={post.author} timestamp={post.timestamp} userId={post.userId} isBeta={post.isBeta} userPhoto={post.photo} uplodedFile={post.uploadedFile} />
                            ))}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </VisualizationComponent>
            <ToastContainer />
        </>
    )
}