import React from "react";
import styled from "styled-components";

import { 
    PiBellFill,
    PiHouseLine,
    PiImage,
    PiTree,
    PiCouch,
    PiCrane,
    PiCubeFocus,
    PiLamp
} from "react-icons/pi";

import { 
    Divider 
} from "@chakra-ui/react";

// Producers List
import ProducersListComponent from "../../components/Producers";

const ProducersComponent = styled.div`
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    background: #FFF;
`;

const ScrollItensComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 60px;
    background: #FFF;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const ScrollItem = styled.div`
    width: 70px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFF;
    transition: background-color 0.1s ease-in;
    border-radius: 6px;
    padding: 3px 5px;
    gap: 3px;

    &:hover {
        background-color: #EEE;
        transition: background-color 0.1s ease-in;
        cursor: pointer;
    }
`;

const FilterSelect = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    gap: 15px;
    margin-top: 10px;
`;

const FilterButton = styled.button`
    outline: none;
    border: 1px solid #CCC;
    width: 100px;
    height: 25px;
    border-radius: 999px;
    font-family: 'Archivo', sans-serif;
    font-size: 12px;
    font-weight: 600;
    transition: 0.1s all ease-in;

    &:hover {
        background-color: #CCC;
        color: #000;
        transition: 0.1s all ease-in;
    }
`;

const AlignProducersList = styled.div`
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function Producers() {
    const items = [
        { name: 'Arquitetura', icon: <PiHouseLine size={18} /> },
        { name: 'Design de Interiores', icon: <PiImage size={18} /> },
        { name: 'Paisagismo', icon: <PiTree size={18} /> },
        { name: 'Construtoras', icon: <PiCrane size={18} /> },
        { name: 'Modelagem 3D', icon: <PiCubeFocus size={18} /> },
        { name: 'Móveis Planejados', icon: <PiCouch size={18} /> },
        { name: 'Luminotecnia', icon: <PiLamp size={18} /> },
    ];

    return (
        <ProducersComponent>
            <ScrollItensComponent>
                {items.map((item, index) => (
                    <ScrollItem key={index}>
                        {item.icon}
                        <span style={{ fontSize: 10, fontWeight: 600, fontFamily: 'Archivo' }}>{item.name}</span>
                    </ScrollItem>
                ))}
            </ScrollItensComponent>
            <Divider />
            <FilterSelect>
                <FilterButton>Projetos</FilterButton>
                <FilterButton style={{ backgroundColor: '#CCC' }}>Produtores</FilterButton>
                <FilterButton>Postagens</FilterButton>
            </FilterSelect>
            <AlignProducersList>
                <ProducersListComponent />
            </AlignProducersList>
        </ProducersComponent>
    )
}