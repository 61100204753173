import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../Utils/database/firebase";

// ChakraIcons
import { Search2Icon } from '@chakra-ui/icons'

import {
    PiBellFill,
    PiHouseLine,
    PiImage,
    PiTree,
    PiCouch,
    PiCrane,
    PiCubeFocus,
    PiLamp
} from "react-icons/pi";

// ChakraUI
import {
    Input,
    InputGroup,
    InputRightAddon,
    Avatar,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    Divider,
    Button
} from '@chakra-ui/react'

// Logo
import Logo from '../../Assets/img/zlogo.svg';

const HeaderComponent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: #f9fbfc;
    margin: 0;
    padding: 0 35px;
    z-index: 1000;
`;

const LogoImg = styled.img`
    width: 45px;
    height: 45px;
`;

const UserComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
`;

const SearchContainer = styled.div`
    position: relative;
    width: 600px;
`;

const SearchInput = styled(Input)`
    position: relative;
    z-index: 2;
    background-color: #FFF;
`;

const SearchResults = styled.div`
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 50px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    overflow-y: auto;
    gap: 10px;
`;

const ScrollItensComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 60px;
`;

const ScrollItem = styled.div`
    width: 70px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFF;
    transition: background-color 0.1s ease-in;
    border-radius: 6px;
    padding: 3px 5px;
    gap: 3px;

    &:hover {
        background-color: #EEE;
        transition: background-color 0.1s ease-in;
        cursor: pointer;
    }
`;

const FilterSelect = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 30px;
    gap: 15px;
`;

const FilterButton = styled.button`
    outline: none;
    border: 1px solid #CCC;
    width: 100px;
    height: 25px;
    border-radius: 999px;
    font-family: 'Archivo', sans-serif;
    font-size: 12px;
    font-weight: 600;
    transition: 0.1s all ease-in;

    &:hover {
        background-color: #CCC;
        color: #000;
        transition: 0.1s all ease-in;
    }
`;

export default function Header() {
    const [followers, setFollowers] = useState([]);
    const [searchFocused, setSearchFocused] = useState(false);
    const [users, setUsers] = useState([]);

    // Configuração do Slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    };

    useEffect(() => {
        // Buscar seguidores
        const fetchFollowers = async () => {
            const uid = localStorage.getItem('uid');
            if (uid) {
                try {
                    const followersCollection = collection(db, "users", uid, "followers");
                    const querySnapshot = await getDocs(followersCollection);

                    if (!querySnapshot.empty) {
                        const followerIds = querySnapshot.docs.map(doc => doc.id);
                        const followersData = await Promise.all(followerIds.map(async (followerId) => {
                            const userDocRef = doc(db, "users", followerId);
                            const userDoc = await getDoc(userDocRef);
                            return userDoc.exists() ? userDoc.data() : null;
                        }));
                        setFollowers(followersData.filter(data => data !== null));
                    }
                } catch (error) {
                    console.error("Erro ao buscar seguidores", error);
                }
            }
        };

        // Realizar busca de todos os usuários
        const fetchAllUsers = async () => {
            try {
                const usersCollection = collection(db, "users");
                const usersSnapshot = await getDocs(usersCollection);
                const usersList = await Promise.all(
                    usersSnapshot.docs.map(async (doc) => {
                        const userData = { id: doc.id, ...doc.data() };
                        const galleryRef = collection(doc.ref, "gallery");
                        const gallerySnapshot = await getDocs(galleryRef);

                        if (!gallerySnapshot.empty) {
                            userData.galleryImages = gallerySnapshot.docs.map(doc => doc.data().photo);
                        } else {
                            userData.galleryImages = [];
                        }

                        return userData;
                    })
                );
                setUsers(usersList);
            } catch (error) {
                console.error("Erro ao buscar usuários:", error);
            }
        };

        fetchFollowers();
        fetchAllUsers();
    }, []);

    const items = [
        {
            name: 'Arquitetura',
            icon: <PiHouseLine size={18} />,
            feedName: 'arquitetura'
        },
        {
            name: 'Design de Interiores',
            icon: <PiImage size={18} />,
            feedName: 'design_de_interiores'
        },
        {
            name: 'Paisagismo',
            icon: <PiTree size={18} />,
            feedName: 'paisagismo'
        },
        {
            name: 'Construtoras',
            icon: <PiCrane size={18} />,
            feedName: 'construtoras'
        },
        {
            name: 'Modelagem 3D',
            icon: <PiCubeFocus size={18} />,
            feedName: 'modelagem_3d'
        },
        {
            name: 'Móveis Planejados',
            icon: <PiCouch size={18} />,
            feedName: 'moveis_planejados'
        },
        {
            name: 'Luminotecnia',
            icon: <PiLamp size={18} />,
            feedName: 'luminotecnia'
        },
    ]

    return (
        <HeaderComponent>
            <Link to='/home'>
                <LogoImg src={Logo} />
            </Link>
            <SearchContainer>
                <InputGroup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999 }}>
                    <SearchInput
                        placeholder=''
                        size='md'
                        onFocus={() => setSearchFocused(true)}
                        style={{ backgroundColor: '#FFF' }}
                    />
                    <InputRightAddon style={{ backgroundColor: '#FFF', borderLeftColor: '#FFF', borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>
                        <Search2Icon />
                    </InputRightAddon>
                </InputGroup>

                <SearchResults visible={searchFocused} onFocus={() => setSearchFocused(true)} onBlur={() => setSearchFocused(false)}>
                    <ScrollItensComponent>
                        {items.map((item, index) => (
                            <Link to={`/feed/${item.feedName}`} key={index}>
                                <ScrollItem>
                                    {item.icon}
                                    <span style={{ fontSize: 10, fontWeight: 600, fontFamily: 'Archivo' }}>{item.name}</span>
                                </ScrollItem>
                            </Link>
                        ))}
                    </ScrollItensComponent>
                    <Divider />
                    <FilterSelect>
                        <FilterButton>Projetos</FilterButton>
                        <Link to='/producers'>
                            <FilterButton>Produtores</FilterButton>
                        </Link>
                        <FilterButton>Postagens</FilterButton>
                    </FilterSelect>
                    <div className="slider-container" style={{ width: '100%', height: 400 }}>
                        <Slider {...settings}>
                            {users.map((user, index) => (
                                <div key={index} style={{ width: '200px' }}>
                                    <Link
                                        to={`/profile/${user.id}`}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                height: 100,
                                                backgroundImage: user.galleryImages && user.galleryImages.length > 0
                                                    ? `url(${user.galleryImages[0]})`
                                                    : 'none',
                                                backgroundSize: 'cover',
                                                objectFit: 'cover',
                                                borderLeft: '4px solid #FFF',
                                                position: 'relative',
                                            }}
                                        >
                                            <div style={{ position: 'absolute', gap: 10, bottom: 0, width: '100%', height: 50, padding: '0 10px', display: 'flex', flexDirection: 'row', alignItems: 'center', background: 'linear-gradient(to top, rgba(31, 31, 31, 0.6) 0%, rgba(31, 31, 31, 0.02) 90%)', }}>
                                                <Avatar
                                                    name={user.firstName + " " + user.lastName}
                                                    src={user.photo || undefined}
                                                    size='sm'
                                                />
                                                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '120px' }}>
                                                    <span style={{ fontFamily: 'Archivo', fontSize: 11, fontWeight: 600, color: '#FFF' }}>{user.firstName + " " + user.lastName}</span>
                                                    <span style={{ fontFamily: 'Archivo', fontSize: 10, fontWeight: 400, color: '#CCC', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.description}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </SearchResults>
            </SearchContainer>
            <UserComponent>
                {
                    localStorage.getItem('email') ?
                    <Avatar
                        name={localStorage.getItem('firstName') + " " + localStorage.getItem('lastName')} style={{ width: 35, height: 35, marginRight: 10 }}
                        src={localStorage.getItem('photo') || undefined}
                    />
                    :
                    <Link to='/'>
                        <Button colorScheme='blue' size='sm' fontFamily='Archivo' marginRight={5}>ENTRAR</Button>
                    </Link>
                }
                <Popover trigger='hover'>
                    <PopoverTrigger>
                        <PiBellFill size={18} color='#656565' cursor='pointer' />
                    </PopoverTrigger>
                    <PopoverContent style={{ right: 250, top: 60 }}>
                        <PopoverHeader style={{ fontFamily: 'Archivo' }}>Notificações</PopoverHeader>
                        <PopoverBody style={{ maxHeight: '50%', overflowY: 'auto' }}>
                            {followers.length > 0 ? (
                                followers.map((follower, index) => (
                                    <div key={index} style={{ marginBottom: 10 }}>
                                        <Avatar name={follower.firstName + " " + follower.lastName} style={{ width: 30, height: 30, marginRight: 10 }} />
                                        <span style={{ fontFamily: 'Archivo', fontSize: 12, fontWeight: '600' }}>{follower.firstName + " " + follower.lastName} <span style={{ fontWeight: '400' }}>começou a seguir você.</span></span>
                                    </div>
                                ))
                            ) : (
                                <div>Não há nada por aqui...</div>
                            )}
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </UserComponent>
        </HeaderComponent>
    );
}
